
import { Vue, Options } from 'vue-class-component';
import EncounterPage1Night from '../build-up-1-night/EncounterPage.vue';
import { SavingStatus } from '@/lib/constants';
import { Laterality } from '@/custom/menicon/models';

@Options({
  components: {
    EncounterPage1Night
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyType: {
      type: String,
      default: null
    },
    encounterId: {
      type: String,
      default: null
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    encounter: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    }
  }
})
export default class EncounterPage extends Vue {}
